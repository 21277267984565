import React from 'react'
import './Contact.css'
import { useLocation } from 'react-router-dom'
import axios from 'axios'

export default function Contact() {
    let { state } = useLocation()

    const [formData, setFormData] = React.useState({
        firstName: '',
        lastName: '',
        email: '',
        category: 'Driver',
        interests: '',
        phone: '',
    })

    const [submitted, setSubmitted] = React.useState(false)

    React.useEffect(() => {
        if (state?.email) {
            setFormData({
                ...formData,
                email: state.email,
            })
        }
    }, [])

    const handleChange = (e) => {
        console.log(formData)
        const { name, value } = e.target

        setFormData({
            ...formData,
            [name]: name === 'phone' ? formatPhoneNumber(value) : value,
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        // Normalize data for Sheets
        const data = {
            Email: formData?.email,
            FirstName: formData?.firstName,
            LastName: formData?.lastName,
            Category: formData?.category,
            Interests: formData?.interests,
            Phone: formData?.phone,
        }
        axios.post('https://sheet.best/api/sheets/006a9c83-49e8-428b-b9c1-39c02ef140f1', data).then((res) => {
            setFormData({
                firstName: '',
                lastName: '',
                email: '',
                category: 'Driver',
                interests: '',
                phone: '',
            })
            setSubmitted(true)
        })
    }

    const formatPhoneNumber = (phoneNumber) => {
        const cleanedPhoneNumber = phoneNumber.replace(/\D/g, '') // Remove non-numeric characters
        if (cleanedPhoneNumber.length <= 3) {
            return cleanedPhoneNumber
        } else if (cleanedPhoneNumber.length <= 6) {
            return `${cleanedPhoneNumber.slice(0, 3)}-${cleanedPhoneNumber.slice(3)}`
        } else {
            return `${cleanedPhoneNumber.slice(0, 3)}-${cleanedPhoneNumber.slice(3, 6)}-${cleanedPhoneNumber.slice(6, 10)}`
        }
    }

    return (
        <div className="contact-page-container">
            <h2>Join our Waitlist</h2>
            <p>Enter your email and we'll keep you updated on our release!</p>
            <form
                className="contact-form"
                onSubmit={handleSubmit}>
                <div className="first-name-input">
                    <label htmlFor="firstName">
                        First Name<span style={{ color: 'red' }}>*</span>
                    </label>
                    <input
                        type="text"
                        id="firstName"
                        name="firstName"
                        value={formData.firstName}
                        placeholder="John"
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="last-name-input">
                    <label htmlFor="lastName">
                        Last Name<span style={{ color: 'red' }}>*</span>
                    </label>
                    <input
                        type="text"
                        id="lastName"
                        name="lastName"
                        value={formData.lastName}
                        placeholder="Doe"
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="email-input">
                    <label htmlFor="email">
                        Email<span style={{ color: 'red' }}>*</span>
                    </label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        placeholder="name@gmail.com"
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="number-input">
                    <label htmlFor="phone">Phone Number</label>
                    <input
                        type="tel"
                        id="phone"
                        name="phone"
                        maxLength={12}
                        value={formatPhoneNumber(formData.phone)}
                        placeholder="XXX-XXX-XXXX"
                        onChange={handleChange}
                    />
                </div>
                <div className="user-type-input">
                    <label htmlFor="category">
                        Are you interested in our Driver, Host, or Parking Lot service?<span style={{ color: 'red' }}>*</span>
                    </label>
                    <select
                        id="category"
                        name="category"
                        value={formData.category}
                        onChange={handleChange}>
                        <option value="Driver">Driver</option>
                        <option value="Host">Host</option>
                        <option value="Parking Lot">Parking Lot</option>
                    </select>
                </div>
                <div className="interests-input">
                    <label htmlFor="interests">What interests you about Parket?</label>
                    <textarea
                        id="interests"
                        name="interests"
                        value={formData.interests}
                        onChange={handleChange}
                        placeholder="I want to rent my driveway..."
                    />
                </div>
                {submitted ? <p>Your submission was successful! We'll update you soon.</p> : null}
                <div className="submit-btn">
                    <button type="submit">Submit</button>
                </div>
            </form>
        </div>
    )
}
