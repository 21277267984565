import './App.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

// Component Imports
import Navbar from './components/NavBar/Navbar'
import Landing from './components/Landing/Landing'
import Contact from './components/Contact/Contact'
import About from './components/About/About'
import Footer from './components/Footer/Footer'
import Return from './components/Return/Return'

function App() {
    return (
        <Router>
            <div className="app">
                <Navbar />
                <div>
                    <Routes>
                        <Route
                            path="/"
                            element={<Landing />}
                        />
                        <Route
                            path="/contact"
                            element={<Contact />}
                        />
                        <Route
                            path="/about"
                            element={<About />}
                        />
                        <Route
                            path="/return"
                            element={<Return />}
                        />
                    </Routes>
                </div>
                <Footer />
            </div>
        </Router>
    )
}

export default App
