import React from 'react'
import './Return.css'
import parketLogoTextRight from '../../assets/logo-text-right.png'
import backgroundImage from '../../assets/return-bg.png'

export default function Return() {
    const handleClick = () => {
        // This doesn't do anything unless in a dev/prod build
        // window.location.href = 'parket://'
    }

    return (
        <div className="return-page-container">
            <img
                className="logo"
                src={parketLogoTextRight}
            />
            <img
                src={backgroundImage}
                className="background-img"
            />
            <div>
                <button
                    className="btn"
                    onClick={handleClick}>
                    Return back to app
                </button>
            </div>
        </div>
    )
}
