import React from 'react'

import './About.css'
import delandPhoto from '../../assets/delandPhoto.webp'
import abdullahPhoto from '../../assets/abdullahPhoto.webp'
import milesPhoto from '../../assets/milesPhoto.webp'
import melikPhoto from '../../assets/melikPhoto.webp'

export default function About() {
    return (
        <div className="about-page-container">
            <div className="photos-banner">
                <img
                    className="photos-img"
                    src={delandPhoto}
                    alt="Deland"
                />
                <img
                    className="photos-img"
                    src={abdullahPhoto}
                    alt="Abdullah"
                />
                <img
                    className="photos-img"
                    src={milesPhoto}
                    alt="Miles"
                />
                <img
                    className="photos-img"
                    src={melikPhoto}
                    alt="Melik"
                />
            </div>
            <h1 className="about-heading">About Us</h1>
            <div className="about-text">
                <p>We are a diverse team of driven engineers who are changing how parking works.</p>
                <p>
                    Together, we've built a simple, frictionless medium connecting cars and parking spots, drivers and hosts. How do we use spaces around us efficiently and sustainably? Parket is a
                    new algorithm playing out in the real world.
                </p>
                <p style={{ textAlign: 'right' }}>— Deland, Abdullah, Miles, and Melik</p>
            </div>
        </div>
    )
}
