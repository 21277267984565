import React from 'react'
import { Link } from 'react-router-dom'

import './Landing.css'
import landingBanner from '../../assets/hero-demo.png'
import DriverIcon1Svg from '../../assets/driver-icon-1.png'
import DriverIcon2Svg from '../../assets/driver-icon-2.png'
import DriverIcon3Svg from '../../assets/driver-icon-3.png'
import HostIcon1Svg from '../../assets/host-icon-1.png'
import HostIcon2Svg from '../../assets/host-icon-2.png'
import HostIcon3Svg from '../../assets/host-icon-3.png'

export default function Landing() {
    const [email, setEmail] = React.useState('')

    const handleChange = (e) => {
        const { name, value } = e.target
        setEmail(value)
    }

    return (
        <div className="landing-page-container">
            <div className="section-banner">
                <div className="introduction-container">
                    <h1 className="slogan">
                        We're your versatile <span style={{ color: '#316cf6' }}>Parking</span> Solution.
                    </h1>
                    <h3 className="sub-slogan">Find a parking spot or rent out your driveway.</h3>

                    <h2 className="slogan-coming">
                        {'['}
                        <span style={{ color: '#316cf6' }}>Launching Soon in NYC!</span>
                        {']'}
                    </h2>

                    <div className="introduction-buttons-container">
                        <Link
                            to="contact"
                            className="learn-more-btn">
                            Learn More
                        </Link>
                    </div>
                </div>
                <div className="banner-container">
                    <img
                        className="banner-img"
                        src={landingBanner}
                    />
                </div>
            </div>
            <div className="waitlist">
                <h3 className="waitlist-title">Join our waitlist to receive news and early access!</h3>
                <div className="waitlist-form">
                    <input
                        className="waitlist-input"
                        placeholder="name@email.com"
                        onChange={handleChange}
                        value={email}
                    />
                    <Link
                        className="waitlist-btn"
                        state={{ email: email }}
                        to="contact">
                        Join
                    </Link>
                </div>
            </div>
            <div className="renter-container">
                <h2 className="renter-title"> Drivers </h2>
                <p className="renter-desc">Find parking for your event or daily commute. Book a spot imediately or schedule in advance.</p>
                <div className="renter-slider-container">
                    <div className="renter-slider">
                        <img
                            className="renter-slider-img"
                            src={DriverIcon1Svg}
                            alt="Your SVG"
                        />
                        <h3 className="renter-slider-title"> Search </h3>
                        <p className="renter-slider-desc">Filter by time and location to find the perfect spot</p>
                    </div>
                    <div className="renter-slider">
                        <img
                            className="renter-slider-img"
                            src={DriverIcon2Svg}
                            alt="Your SVG"
                        />
                        <h3 className="renter-slider-title"> Book </h3>
                        <p className="renter-slider-desc">Confirm and reserve your spot</p>
                    </div>
                    <div className="renter-slider">
                        <img
                            className="renter-slider-img"
                            src={DriverIcon3Svg}
                            alt="Your SVG"
                        />
                        <h3 className="renter-slider-title"> Park </h3>
                        <p className="renter-slider-desc">Follow easy directions and access instructions</p>
                    </div>
                </div>
            </div>
            <div className="divider" />
            <div className="host-container">
                <h2 className="host-title"> Hosts </h2>
                <p className="host-desc">Make easy money by renting out your parking space and earn up to $300 a month. It's free to list.</p>
                <div className="host-slider-container">
                    <div className="host-slider">
                        <img
                            className="host-slider-img"
                            src={HostIcon1Svg}
                            alt="Your SVG"
                        />
                        <h3 className="host-slider-title"> List </h3>
                        <p className="host-slider-desc">Add your location, availabilities, and rates</p>
                    </div>
                    <div className="host-slider">
                        <img
                            className="host-slider-img"
                            src={HostIcon2Svg}
                            alt="Your SVG"
                        />
                        <h3 className="host-slider-title"> Scheduling </h3>
                        <p className="host-slider-desc">Wait for drivers. We'll handle the scheduling and payments</p>
                    </div>
                    <div className="host-slider">
                        <img
                            className="host-slider-img"
                            src={HostIcon3Svg}
                            alt="Your SVG"
                        />
                        <h3 className="host-slider-title"> Earnings </h3>
                        <p className="host-slider-desc">Track your earnings and manage payouts</p>
                    </div>
                </div>
            </div>
            <div className="waitlist-mid">
                <h3 className="waitlist-mid-title">Join our waitlist to receive news and early access!</h3>
                <div className="waitlist-mid-form">
                    <input
                        className="waitlist-input-mid"
                        placeholder="name@email.com"
                        value={email}
                        onChange={handleChange}
                    />
                    <Link
                        className="waitlist-btn-mid"
                        state={{ email: email }}
                        to="contact">
                        Join
                    </Link>
                </div>
            </div>
            <div className="parking-lot-container">
                <div className="parking-lot-details">
                    <h2 className="parking-lot-title">Own a small parking lot?</h2>
                    <p className="parking-lot-text">Add a new revenue stream or maximize yield from underused car parks and vacant land with Parket.</p>
                    <Link
                        className="parking-lot-learn"
                        to="contact">
                        Learn More
                    </Link>
                </div>
            </div>
        </div>
    )
}
